import { useState } from 'react'
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Checkbox, Form, Grid, Image, Input } from 'semantic-ui-react';

import { login } from '../util/requests';
import "./login.sass"
import CompanySelection from '../companySelection/CompanySelection';
import { Store } from 'react-notifications-component';
import { renderNotification } from '../../util/util';

function Login(){
    const { t } = useTranslation();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [remember, setRemember] = useState(false);
    const [sending, setSending] = useState(false);
    const [loginError, setLoginError] = useState(false);

    const modalCompanySelectionDefault = {
        openModal: false,
        companies: []
    }
    const [{openModal, companies}, setModalCompanySelection] = useState(modalCompanySelectionDefault)
    


    const navigate = useNavigate();
    
    const loginProcess = () =>{
        setSending(true)
        login(email, password).then((response) => {
            const comp = response.data.idCompanies ? response.data.idCompanies : []
            if(comp.length === 0 && !response.data["partner"]){
                Store.addNotification(
                    renderNotification("error", t("SIGNIN"), t("SIGN_IN_ERROR"))
                )
                setSending(false)
                return
            }
            localStorage.setItem("AT", response.data["X-API-KEY"])
            if(response.data["partner"]){
                navigate("/partner")    
            }
            if(comp.length > 1){
                setModalCompanySelection({
                    openModal: true,
                    companies: comp
                })
                setSending(false)
                return
            }
            localStorage.setItem('c-id', comp[0]["idCompany"])
            navigate("/dashboard")
            //console.log("RESPONSE", response.data["X-API-KEY"])
        }).catch((err) => {
            setLoginError(true)
            if(!err.hasOwnProperty("response")){
                // SHOW MESSAGE "THERE WAS AN ERROR WITH THE SERVER, TRYA GAIN LATER"
            }else{
                
            }
                /*
                STAUTS 401: SHOW MESSAGE "WRONG PASSWORD"

                ELSE

                SHOW MESSAGE "THERE WAS AN ERROR WITH THE SERVER, TRYA GAIN LATER"
            */
            
            //console.log("ERROR", err.response.status)
        })
    }

    // useEffect(()=>{
    //     console.log(password)
    // }, [password])
    return (
        <Grid className="loginPage noMargin" centered style={{backgroundImage: "url(/bg-login.png)"}}>
            <Grid.Column computer={6} tablet={9} mobile={10}>
                <Grid centered>
                    <Grid.Column computer={8} tablet={10} mobile={16} className="marginTop">
                        <Image src="/white-mainicon.png"  className="chooseFWImg"/>
                        <p className='welcome'>
                            {t("WELCOME!")}
                        </p>
                        <Form>
                            <Form.Field>
                                <label className='whiteColor centerText'>{t('EMAIL')}:</label>
                                <Input 
                                    type='email' 
                                    placeholder='example@mail.com' 
                                    className={loginError ? 'loginError' : ''}
                                    onChange={(e, {value}) => setEmail(value)} 
                                    fluid 
                                    required
                                />
                            </Form.Field>
                            <Form.Field>
                                <label className='whiteColor centerText'>{t('PASSWORD')}:</label>
                                <Input 
                                    type='password' 
                                    className={loginError ? 'loginError' : ''}
                                    onChange={(e, {value}) => setPassword(value)} 
                                    fluid 
                                    required
                                />
                            </Form.Field>
                            <Grid centered>
                                <Grid.Column width={16}>
                                    <Grid>

                                        <Grid.Column computer={8} tablet={16} mobile={16}>
                                            <Form.Field>
                                                <Checkbox className='labelComponentWhite' label={t('REMEMBER_ME')} onChange={(e, {checked}) => setRemember(checked)}/>
                                            </Form.Field>
                                        </Grid.Column>
                                    </Grid>
                                </Grid.Column>
                                <Grid.Column width={16} className='centerText'>
                                    <Link to="signup">
                                        {<span className='link'>Sign up</span>}
                                    </Link>

                                </Grid.Column>
                                <Grid.Column width={16} className='centerText'>
                                    <Link to="partnersignup">
                                        {<span className='link'>Sign up as partner</span>}
                                    </Link>
                                </Grid.Column>
                                {loginError && 
                                    <Grid.Column width={10} className="errorMessage">
                                        <h4>{t('INVALID_CREDENTIALS')}</h4>
                                    </Grid.Column>
                                }
                                <Grid.Column width={14}>
                                    <Button fluid className='buttonBlue' onClick={() => loginProcess()}>{t('CONTINUE')}</Button>
                                </Grid.Column>
                                <Grid.Column width={16}>
                                    <Link to="login" style={{display: "inline-block", width:"100%"}}>
                                        <span className='back'>&#8592;{t('GO_BACK_SIMPLIX')}</span>
                                    </Link>
                                </Grid.Column>
                            </Grid>
                        </Form>
                    </Grid.Column>
                </Grid>
            </Grid.Column>
            <CompanySelection
                open={openModal}
                closeModal={() => setModalCompanySelection(modalCompanySelectionDefault)}
                companies={companies}
            />
        </Grid>
    )
}

export default Login;